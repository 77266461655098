import React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
const AGENCY_CONTACT_US = 'https://www.cityofhenderson.com/government/contact-us';
function Footer({ className }) {
    return (<footer className={cx("footer", className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Comcate, Inc.
        </p>
        <nav className="footer-nav">
          <Trans>Contact Us</Trans>: 951-677-7751 Ext. 214
        </nav>
      </div>
    </footer>);
}
export default Footer;
