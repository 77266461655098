import React from "react";
import cx from "classnames";
import Alert from "../Alert";
import LoadingBar from "../LoadingBar";
import { useHistory, useParams } from 'react-router-dom';
function PageLayout({ children, className, header, footer }) {
    const history = useHistory();
    const params = useParams();
    return (<main className={cx("page bg-color page-text-color", className)}>
      <LoadingBar />
      {header}
      <Alert />
      <div className="body body-height">
        <div className="body-wrapper">{children}</div>
      </div>
      {footer}
    </main>);
}
export default PageLayout;
