import * as React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useTranslation, useCategories } from "@elevio/kb-kit/lib/hooks";
import * as Search from "@elevio/kb-kit/lib/components/search";
import * as Categories from "@elevio/kb-kit/lib/components/categories";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MainSection, GridContainer, GridItem, } from "../components/layouts/Main";
import ArticleList from "../components/ArticleList";
import Breadcrumb from "../components/PageBreadcrumbs";
import * as Meta from "@elevio/kb-kit/lib/components/meta";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
export const brandLogo = `${window.assetsLocation}/images/logo.svg`;
export const brandShowcaseUrl = `${window.assetsLocation}/images/brand-showcase.jpg`;
export const leftBannerIcon = `${window.assetsLocation}/images/left-banner-icon.svg`;
export const rightBannerIcon = `${window.assetsLocation}/images/right-banner-icon.svg`;
// export const bannerUrl = `${window.assetsLocation}/images/wildomar-banner.jpg`;
export const viewReportIconUrl = `${window.assetsLocation}/images/carousel.png`;
export const getHeader = () => {
    const { t } = useTranslation();
    return (<Header hideSearch breadcrumbs={<div className="breadcrumbs-header-wrapper">
          <Breadcrumb className="breadcrumbs-header"/>
        </div>}>
      <img src={leftBannerIcon} className="banner-image" width="120"/>
      <div className="search-area">
        <h1>
          <Trans i18nKey="home.welcome">
            How can we assist?
          </Trans>
        </h1>
        <Search.Input placeholder={t("search.placeholder", "Search help articles...")} data-testid="search-input" className="hero-search search-bar"/>
      </div>
      <img src={rightBannerIcon} className="banner-image" width="120"/>
    </Header>);
};
const isIOS = () => {
    return (navigator.userAgent.match(/iPhone/i) != null);
};
const HomePage = () => {
    const { categories } = useCategories();
    return (<PageLayout header={getHeader()} footer={<Footer />}>
      <MainSection className="page-text-color">
        <div className="ask-arvada-logo-wrapper">
          <div className={cx('submit-request-link')}>
            <Meta.ExtraPageLink path="submitRequest">
              <button className={cx('submit-request')}>
                  <FontAwesomeIcon icon={faEnvelope} size="lg" transform="left-4"/> Submit Request
                </button>
            </Meta.ExtraPageLink>
          </div>
        </div>
        <GridContainer>
          <Categories.Loop>
            <GridItem>
              <ArticleList />
            </GridItem>
          </Categories.Loop>
        </GridContainer>
        {/* Code to show subcategories and there artice listing. Maybe required in the future. */}
        {/* <GridContainer>
          {categories.map(category => (
            <Categories.Loop parentCategoryId={category && category.id}>
              <GridItem>
                <ArticleList />
              </GridItem>
            </Categories.Loop>
          ))}
        </GridContainer> */}
      </MainSection>
    </PageLayout>);
};
export default HomePage;
